(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/*jslint node: true, browser: true */
'use strict';

require('./ui/relative-time')();
require('./ui/sidebar')();

},{"./ui/relative-time":2,"./ui/sidebar":3}],2:[function(require,module,exports){
/*jslint node: true, browser: true */
/*global moment */
'use strict';

function makeTimeRelative(timeElement) {
    var time = moment(timeElement.getAttribute('datetime'));

    timeElement.textContent = time.fromNow();
}

module.exports = function () {
    var i,
        times = document.querySelectorAll('time[datetime]');

    for (i = 0; i < times.length; i += 1) {
        makeTimeRelative(times[i]);
    }
};

},{}],3:[function(require,module,exports){
/*jslint node: true, browser: true */
'use strict';

module.exports = function () {
    var i,
        toggleButtons = document.querySelectorAll('[data-action~="toggle-sidebar"]'),
        sidebarWrap = document.querySelector('.sidebar-wrap'),
        sidebar = document.querySelector('#sidebar');

    function handleToggleClicked() {
        if (sidebar.hasAttribute('data-visible')) {
            sidebar.removeAttribute('data-visible');
            sidebarWrap.removeAttribute('data-visible');
        } else {
            sidebar.setAttribute('data-visible', '');
            sidebarWrap.setAttribute('data-visible', '');
        }
    }

    for (i = 0; i < toggleButtons.length; i += 1) {
        toggleButtons[i].addEventListener('click', handleToggleClicked);
    }
};

},{}]},{},[1]);
